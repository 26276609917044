import React, { useEffect, useState } from 'react';
import { HomeActionsProps } from '../../types';
import { Link } from 'react-router-dom';
import { ActButton } from './styles';
import { toast } from 'react-toastify';
import { verificarCodTela } from '../../utils/verificarCodTela';

export const ButtonAction: React.FC<HomeActionsProps> = ({
  label,
  href,
  icon: Icon,
  codTela,
  user,
}) => {
  return (
    <Link
      to={href}
      onClick={() => {
        if (!verificarCodTela(user.user.controleAcessoTela, codTela)) {
          toast.warning(
            `Ação não habilitada para seu usuário.\nConsulte o administrador do sistema.`,
          );
          const homeTab = document.getElementById('204-tab');
          setTimeout(() => {
            if (homeTab) homeTab.click();
          }, 500);
        }
      }}
    >
      <ActButton type="button">
        <Icon size={23} />
        {label}
      </ActButton>
    </Link>
  );
};
