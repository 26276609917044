import styled from 'styled-components';

export const ButtonDash = styled.button`
  border: none;
  background-color: transparent;

  svg {
    color: #009e11;
  }

  &:disabled {
    cursor: not-allowed;
  }

  &:focus {
    outline: none;
  }
`;

export const ButtonGraficoVenda = styled.button`
  border: none;
  padding: 0.4375rem;

  font-size: medium;

  border-right: 1px solid #57069e;
  background-color: transparent;

  &:hover {
    background-color: rgba(87, 6, 158, 0.2);
    color: rgba(0, 0, 0, 0.9);
  }

  &:disabled {
    cursor: not-allowed;
    color: rgba(0, 0, 0, 0.9);
  }

  &:focus {
    outline: none;
  }
`;

export const TipoRequisitoContainer = styled.div`
  display: flex;
  gap: 0.1875rem;

  .content-button-requisito,
  .content-button-tipo {
    display: inline-flex;
    border-top: 1px solid #57069e;
    border-bottom: 1px solid #57069e;
    border-left: 1px solid #57069e;
    border-radius: 0.1875rem;
  }
`;
