import styled from 'styled-components';

export const ActButton = styled.button`
  border: 0.0625rem solid #57069e;
  padding: 0.625rem;
  border-radius: 0.3125rem;
  height: 3.75rem;
  width: 100%;
  background-color: white;

  color: black;
  text-align: center;

  svg {
    margin-right: 0.3125rem;
  }

  &:hover {
    background-color: #57069e;
    color: white;
    opacity: 80%;
  }
`;
