import {
  FiBox,
  FiDollarSign,
  FiFileText,
  FiPackage,
  FiPrinter,
  FiSend,
  FiShoppingBag,
  FiTag,
  FiUploadCloud,
  FiUserPlus,
} from 'react-icons/fi';
import { HomeActionsProps } from '../types';
import { AiOutlineUserAdd, AiOutlineUsergroupAdd } from 'react-icons/ai';

export const homeActions: HomeActionsProps[] = [
  {
    label: 'Adicionar novos usuários',
    href: '/usuario',
    icon: AiOutlineUsergroupAdd,
    codTela: 2,
  },
  {
    label: 'Cadastrar cliente',
    href: '/cliente',
    icon: FiUserPlus,
    codTela: 21,
  },
  {
    label: 'Cadastrar fornecedor',
    href: '/fornecedor',
    icon: AiOutlineUserAdd,
    codTela: 24,
  },
  {
    label: 'Cadastrar produto',
    href: '/produto',
    icon: FiPackage,
    codTela: 28,
  },
  {
    label: 'Entrada de mercadoria (NF)',
    href: '/entrada-de-nfe',
    icon: FiFileText,
    codTela: 60,
  },
  {
    label: 'Emitir uma nota fiscal para cliente ',
    href: '/emissao-nfe',
    icon: FiFileText,
    codTela: 47,
  },
  {
    label: 'Emitir Venda (PDV Online)',
    href: '/pdv-online',
    icon: FiShoppingBag,
    codTela: 283,
  },
  {
    label: 'Configurar impressora/balança',
    href: '/regras-de-loja',
    icon: FiPrinter,
    codTela: 25,
  },
  {
    label: 'Imprimir uma etiqueta',
    href: '/impressao-etiqueta',
    icon: FiTag,
    codTela: 296,
  },
  {
    label: 'Ajustar o estoque de um produto',
    href: '/ajuste-de-estoque',
    icon: FiBox,
    codTela: 30,
  },
  {
    label: 'Alterar o preço de um produto',
    href: '/alteracao-de-preco-por-item',
    icon: FiDollarSign,
    codTela: 290,
  },
  {
    label: 'Enviar carga para o PDV',
    href: '/carga',
    icon: FiSend,
    codTela: 44,
  },
  {
    label: 'Enviar carga para balanças',
    href: '/carga',
    icon: FiUploadCloud,
    codTela: 44,
  },
];
