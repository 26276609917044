import styled from 'styled-components';

export const ContainerComum = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.625rem;

  a {
    color: inherit;
    text-decoration: none;
  }

  .first-content,
  .second-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.3125rem;

    border: 1px solid #a7a7a7;
    border-radius: 0.3125rem;

    font-size: medium;

    svg {
      margin-right: 0.3125rem;
    }

    strong {
      margin-left: auto;
    }
  }

  .venda-second-content {
    display: grid;
    grid-template-columns: 41.55% 55%;
    gap: 0.625rem;
  }

  .qtd-card-produtos {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;
  }
`;
