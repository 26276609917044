import React, { useCallback } from 'react';
import { ContainerComum } from '../styles';
import { useDashboard } from '~/pages/Dashboard/hooks/useDashboard';
import {
  CartesianGrid,
  Cell,
  Legend,
  Line,
  LineChart,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { CircularProgress } from '@material-ui/core';

const COLORS = [
  '#0088FE',
  '#00C49F',
  '#FFBB28',
  '#FF8042',
  '#A28FCE',
  '#FF6361',
  '#BC5090',
  '#58508D',
  '#FFA600',
  '#66C2A5',
];

export const BodyVendaGrafico: React.FC = () => {
  const { dashboardData, tipoGraficoVenda, loadingQry, requisitoVenda } =
    useDashboard();

  const extractData = useCallback(
    (key: string, nameField: string, valueField: string) => {
      const data: any = dashboardData;
      const queryResult: any = data?.[key];

      return queryResult?.map((item: any) => ({
        name: item[nameField],
        Valor: item[valueField],
      }));
    },
    [dashboardData],
  );

  const keyFaturamento =
    requisitoVenda.requisito === 'hoje' ? 'hora_cupom' : 'dta_cupom';

  const dataFaturamento =
    extractData('faturamento', keyFaturamento, 'val_total') || [];
  const dataMeioPagamento =
    extractData(
      'faturamentoPorMeioPagamento',
      'des_finalizadora',
      'val_total',
    ) || [];
  const dataDepartamento =
    extractData(
      'faturamentoPorDepartamento',
      'des_departamento',
      'val_total',
    ) || [];

  const formatCurrency = (value: number) => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value);
  };

  return (
    <ContainerComum>
      {loadingQry && (
        <CircularProgress size={28} style={{ color: '#009e11' }} />
      )}

      {!loadingQry && tipoGraficoVenda.tipo === 'Fat' && (
        <>
          {dataFaturamento.length === 0 && (
            <p style={{ textAlign: 'center', fontSize: '18px' }}>
              Nenhum registro encontrado.
            </p>
          )}
          {dataFaturamento.length > 0 && (
            <ResponsiveContainer width="100%" height={405}>
              <LineChart data={dataFaturamento}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip formatter={(value: number) => formatCurrency(value)} />
                <Legend />
                <Line type="monotone" dataKey="Valor" stroke="#8884d8" />
              </LineChart>
            </ResponsiveContainer>
          )}
        </>
      )}
      {!loadingQry && tipoGraficoVenda.tipo === 'MeioPgto' && (
        <>
          {dataMeioPagamento.length === 0 && (
            <p style={{ textAlign: 'center', fontSize: '18px' }}>
              Nenhum registro encontrado.
            </p>
          )}
          {dataMeioPagamento.length > 0 && (
            <ResponsiveContainer width="100%" height={405}>
              <PieChart>
                <Pie
                  data={dataMeioPagamento}
                  dataKey="Valor"
                  nameKey="name"
                  cx="50%"
                  cy="50%"
                  outerRadius={100}
                  fill="#8884d8"
                  label={({ value }) => formatCurrency(value)}
                >
                  {dataMeioPagamento.map((entry: any, index: number) => (
                    <Cell
                      key={`cell-${entry.name}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
                <Tooltip formatter={(value: number) => formatCurrency(value)} />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          )}
        </>
      )}
      {!loadingQry && tipoGraficoVenda.tipo === 'Depto' && (
        <>
          {dataDepartamento.length === 0 && (
            <p style={{ textAlign: 'center', fontSize: '18px' }}>
              Nenhum registro encontrado.
            </p>
          )}
          {dataDepartamento.length > 0 && (
            <ResponsiveContainer width="100%" height={405}>
              <PieChart>
                <Pie
                  data={dataDepartamento}
                  dataKey="Valor"
                  nameKey="name"
                  cx="50%"
                  cy="50%"
                  outerRadius={100}
                  fill="#8884d8"
                  label={({ value }) => formatCurrency(value)}
                >
                  {dataDepartamento.map((entry: any, index: number) => (
                    <Cell
                      key={`cell-${entry.name}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
                <Tooltip formatter={(value: number) => formatCurrency(value)} />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          )}
        </>
      )}
    </ContainerComum>
  );
};
