import React from 'react';
import { BodyVenda } from './BodyVenda';
import { BodyProdutos } from './BodyProdutos';
import { BodyVendaGrafico } from './BodyVendaGrafico';
import { BodyCaixaHoje } from './BodyCaixaHoje';
import { BodyOcorrencias } from './BodyOcorrencias';
import { BodyPagarReceber } from './BodyPagarReceber';

interface DashBodyProps {
  card:
    | 'pagar'
    | 'receber'
    | 'venda'
    | 'produtos'
    | 'venda-grafico'
    | 'caixas-hoje'
    | 'ocorrencias';
  screen?: any[];
}

export const DashBody: React.FC<DashBodyProps> = ({ card, screen }) => {
  if (card === 'pagar' || card === 'receber')
    return <BodyPagarReceber card={card} />;
  if (card === 'venda') return <BodyVenda />;
  if (card === 'produtos') return <BodyProdutos />;
  if (card === 'venda-grafico') return <BodyVendaGrafico />;
  if (card === 'caixas-hoje') return <BodyCaixaHoje />;
  if (card === 'ocorrencias') return <BodyOcorrencias screen={screen} />;

  return <></>;
};
