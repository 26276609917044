import styled from 'styled-components';

export const DashCardContainer = styled.div`
  border: 1px solid #ccc;
  border-radius: 8px;
  width: 100%;
  padding: 16px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);

  &:hover {
    border: 1px solid #57069e;
    box-shadow: 0px 4px 8px rgba(87, 6, 158, 0.2);
  }

  .card-header {
    margin-bottom: 12px;
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 8px;
    background-color: transparent;
  }

  .card-header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .card-header-title {
      font-size: 1.2em;
      margin: 0;
      user-select: none;
    }
  }
`;
