import React from 'react';
import { FiRefreshCw } from 'react-icons/fi';
import { ButtonUpdtAll } from './styles';
import { useDashboard } from '../../hooks/useDashboard';
import { CircularProgress } from '@material-ui/core';

export const BtnUpdateAll: React.FC = () => {
  const { handleUpdateDataDashboard, loadingQry } = useDashboard();

  return (
    <ButtonUpdtAll
      type="button"
      onClick={async () => {
        if (loadingQry) return;
        await handleUpdateDataDashboard('todos');
      }}
      disabled={loadingQry}
    >
      {!loadingQry && (
        <FiRefreshCw
          size={19}
          color="#009e11"
          style={{ marginBottom: '0.25rem' }}
        />
      )}
      {loadingQry && (
        <CircularProgress
          size={19}
          style={{ color: '#009e11', marginTop: '0.125rem' }}
        />
      )}
      {loadingQry ? ' Atualizando...' : ' Atualizar tudo'}
    </ButtonUpdtAll>
  );
};
