import styled from 'styled-components';

export const ButtonUpdtAll = styled.button`
  border: none;
  background-color: transparent;
  font-size: 1.0625rem;
  text-align: center;

  &:disabled {
    cursor: not-allowed;
  }
`;
