import React from 'react';
import { ContainerComum } from '../styles';
import { BsCartPlusFill } from 'react-icons/bs';
import { moneyFormat } from '~/utils/functions';
import { useDashboard } from '~/pages/Dashboard/hooks/useDashboard';
import { Link } from 'react-router-dom';

export const BodyVenda: React.FC = () => {
  const { dashboardData } = useDashboard();

  const venda = dashboardData?.vendas?.[0];

  return (
    <ContainerComum>
      <Link to="/relatorio-vendas-por-dia">
        <div className="first-content">
          <BsCartPlusFill size={25} color="#009E11" />
          Hoje:
          <strong style={{ color: '#009E11' }}>
            R$ {moneyFormat(venda?.val_venda.toFixed(2) || '0')}
          </strong>
        </div>
      </Link>
      <div className="venda-second-content">
        <Link to="/relatorio-vendas-por-dia">
          <div className="second-content">
            Cupons:
            <strong>{venda?.qtd_item || 0}</strong>
          </div>
        </Link>
        <Link to="/relatorio-vendas-por-dia">
          <div className="second-content">
            Ticket:
            <strong>
              R$ {moneyFormat(venda?.val_ticket_medio.toFixed(2) || '0')}
            </strong>
          </div>
        </Link>
      </div>
    </ContainerComum>
  );
};
