import React from 'react';
import { NavigationProps } from './types';
import { DashboardProvider } from './contexts/DashboardContext';
import { DashboardContent } from './DashboardContent';

const Dashboard: React.FC<NavigationProps> = ({ navmenu, activated }) => {
  return (
    <DashboardProvider>
      <DashboardContent screen={navmenu} />
    </DashboardProvider>
  );
};

export default Dashboard;
