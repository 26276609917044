import React, { ReactNode } from 'react';
import { DashCardContainer } from './styles';

interface CardProps {
  title?: string;
  topChildren?: ReactNode;
  topRightButton?: ReactNode;
  topChildrenMiddle?: boolean;
  body: ReactNode;
}

export const Card: React.FC<CardProps> = ({
  title = '',
  topChildren,
  topRightButton,
  topChildrenMiddle = true,
  body,
}) => {
  return (
    <DashCardContainer>
      {(title || topRightButton || topChildren) && (
        <div className="card-header">
          <div className="card-header-content">
            {title && <h5 className="card-header-title">{title}</h5>}
            {topChildren && topChildrenMiddle && <div>{topChildren}</div>}
            {topRightButton && <div>{topRightButton}</div>}
          </div>
          {topChildren && !topChildrenMiddle && <div>{topChildren}</div>}
        </div>
      )}
      <div>{body}</div>
    </DashCardContainer>
  );
};
