import React, {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  DashboardApiResponse,
  DashboardContextData,
  DashboardResponse,
  RequisitoTipo,
  TipoBuscaQry,
} from '../types';
import api from '~/services/api';

export const DashboardContext = createContext({} as DashboardContextData);

export const DashboardProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [loadingQry, setLoadingQry] = useState(false);
  const [loading, setLoading] = useState({
    caixas_hoje: false,
    ocorrencias: false,
    vendas_grafico: false,
  });
  const [dashboardData, setDashboardData] = useState<DashboardResponse | null>(
    null,
  );
  const [requisitoVenda, setRequisitoVenda] = useState<{
    requisito: RequisitoTipo;
  }>({
    requisito: 'hoje',
  });
  const [tipoGraficoVenda, setTipoGraficoVenda] = useState<{
    tipo: 'Fat' | 'MeioPgto' | 'Depto';
  }>({ tipo: 'Fat' });

  const handleUpdateDataDashboard = useCallback(
    async (tipoBusca: TipoBuscaQry) => {
      setLoading({
        caixas_hoje: tipoBusca === 'caixas-hoje',
        ocorrencias: tipoBusca === 'ocorrencias',
        vendas_grafico: tipoBusca === 'venda-grafico',
      });

      if (tipoBusca === 'todos') {
        setDashboardData(null);
        setLoadingQry(true);
      }

      const { data } = await api.get<DashboardApiResponse<DashboardResponse>>(
        `/home-dashboard/${tipoBusca}`,
        { params: requisitoVenda },
      );

      if (data.success) {
        const updateDashboardData = (
          keysToRemove: string[],
          newData: DashboardResponse,
        ) => {
          setDashboardData((prevState) => {
            const updatedState: any = { ...prevState };
            keysToRemove.forEach((key) => {
              delete updatedState[key];
            });
            return updatedState;
          });

          setTimeout(() => {
            setDashboardData((prevState) => ({
              ...prevState,
              ...newData,
            }));
          }, 500);
        };

        switch (tipoBusca) {
          case 'venda-grafico':
            updateDashboardData(
              [
                'faturamento',
                'faturamentoPorMeioPagamento',
                'faturamentoPorDepartamento',
              ],
              data.data,
            );
            break;

          case 'caixas-hoje':
            updateDashboardData(['caixasHoje'], data.data);
            break;

          case 'ocorrencias':
            updateDashboardData(
              ['cuponsTransmitir', 'nfePendentes', 'produtosCarga'],
              data.data,
            );
            break;

          default:
            setDashboardData(data.data);
            break;
        }
      }

      setLoadingQry(false);
      setLoading({
        caixas_hoje: false,
        ocorrencias: false,
        vendas_grafico: false,
      });
    },
    [requisitoVenda],
  );

  useEffect(() => {
    handleUpdateDataDashboard('venda-grafico');
  }, [requisitoVenda, handleUpdateDataDashboard]);

  const handleRequisitoVenda = useCallback((requisito: RequisitoTipo) => {
    setRequisitoVenda({ requisito });
  }, []);

  const handleTipoGraficoVenda = useCallback(
    (tipo: 'Fat' | 'MeioPgto' | 'Depto') => {
      setTipoGraficoVenda({ tipo });
    },
    [],
  );

  return (
    <DashboardContext.Provider
      value={{
        handleUpdateDataDashboard,
        loadingQry,
        dashboardData,
        loading,
        handleRequisitoVenda,
        requisitoVenda,
        tipoGraficoVenda,
        handleTipoGraficoVenda,
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};
